const msg = {
  en: {
    // global
    logout: 'Logout',
    signin: 'SIGN IN / REGISTER',
    magic_link_prompt: "Please check your email for login link. It might in your spam email list.",
    my_wallet:'My Wallet',
    //  ALL NFTs
    hello: 'Welcome to CNFT!',
    view: 'View',
    allnft: 'Discover',
    price: 'Price',
    total: 'Total',
    remains: 'Remains',
    faq: 'FAQ',
    terms: 'Terms',
    privacy: 'Privacy',
    content_rules:'Please create proper content NFT! NO PORNS, NO VIOLENCE, NO DISCRIMINATION. Make sure you own the copyright of your content, you have the responsibility to do all investigation before you create your NFT. All those rules is very important in this CNFT community. Any violation we will DELETE your content or DELETE your account PERMANENTLY with no warning, and keep the right report to law enforcement agencies.',

    // My Settings
    my_settings: 'My Settings',
    upload_cover: 'Upload cover image',
    upload_avatar: 'Upload avatar image',
    nickname: 'Nickname',
    choose_your: 'Choose your',
    bio: 'Bio',
    your: 'Your',
    set_your: 'Set your',
    website: 'website',
    facebook: 'facebook account',
    twitter: 'Twitter account',
    instagram: 'Instagram account',
    video_link: 'Video Link',
    shop_link: 'Shop Link',
    blog_link: 'Blog Link',
    profile: 'Your Profile',
    wallet_address: 'Wallet address on Polygon',
    wallet_key:"Private key",
    matic_balance: 'Matic balance',
    user_type: 'user type',
    referrer: 'referrer userID',
    downgrade: 'Downgrade to Customer',
    upgrade: 'Upgrade to Seller',

    // additional gray text 10-9-2021 // 
    choose_your_nickname: 'Choose your Nickname',
    your_bio: 'Your bio',
    set_your_website: 'Set your website', 
    set_your_facebook: 'Set your Facebook account', 
    set_your_twitter: 'Set your Twitter account',
    set_your_instagram: 'Set your Instagram', 
    set_your_video: 'Set your Video Link', 
    set_your_shop: 'Set your Shop Link', 
    set_your_blog: 'Set your Blog Link', 

    // My Team
    my_team: 'My Team',
    teammates: 'Teammates',

    // My Tokens
    my_nfts: 'My NFT',
    token: 'Token',
    show_on_profile: 'Show on profile page',
    view_origin: 'View NFT Collection',
    transfer:'Transfer',

    // NFT Item Page
    sold_by: 'Sold By',
    free: 'Free',
    create_date: 'Create Date',
    contract: 'Contract',
    author: 'Author',
    about: 'About',
    free_claim: 'Free Claim',
    buy_direct: 'Buy instructions',
    sold_out: 'Sold Out',
    no_payment_option: 'NO PAYMENT OPTIONS',
    more_from_seller: 'More from Seller',
    you_are_buying: 'You are buying',
    cancel: 'CANCEL',
    make_payment: 'CREDIT CARD PAYMENT',
    is_claimed: 'Claimed',

    // My Shop
    my_shop: 'My Shop',
    wallet: 'Wallet',
    create_nft: 'Create NFT',
    join_nft:'Join CNFT for FREE!',
    mint: 'Mint',
    edit: 'Edit',
    listing_for_sale: 'Listing for sale',
    create: '+Create',


    // Create or Edit Page
    edit_nft:'Edit NFT',
    upload_nft: 'Upload NFT image',
    nft_title: 'NFT Title *',
    nft_author: 'NFT Author',
    nft_desc: 'Description of your NFT *',
    external_link: 'External Link',
    url_https: '*URL starts with https://',
    audio: 'Audio URL',
    video: 'Video URL',
    nft_price: 'NFT Price *',
    nft_count: 'NFT Count *',
    token_quantity: 'NFT Token Quantity *',
    payment_options: 'Payment Options',
    credit_card: 'Credit Card',
    mint_now: 'Create NFT',
    // additional gray text 10-9-2021 // 
    choose_your_nft_name: 'Choose your NFT Title', 
    set_your_nft_sell_price: 'Set your NFT sale price',
    set_your_nft_token_amount: 'Set your NFT token quantity', 

    // My Profile
    my_profile: 'My Profile',

    //My Orders
  
    my_orders: 'My Orders', 
    order_id: 'Order ID',
    status: 'Status',
    from: 'From: ',
    pending: 'PENDING',
    done: 'DONE',
    failed: 'FAILED',
    pay: 'PAY',


    // My Sales:
    my_sales: 'My Sales',
    purchase_date: 'Purchase Date',
    purchase_price: 'Purchase Price',

    //links
    link_faq: '/faq',

    buycnftg: 'Buy CNFTG',

    // My Wallet
    cnftg_rewards: 'CNFTG Rewards',
    cnftg_balance:'CNFTG Balance on Polygon',

    high_gas_fee: 'Your request is temporarily restricted due to high gas fee level of Polygon Chain. Please upgrade to Silver/Gold/Rugy user level to bypass it, or wait Polygon chain gas fee back to normal.',
    membership:"Membership",

    CLAIMED:"You have already claimed your free NFT! only 1 free NFT per user.",
    share:"Share & Earn",
    Bronze:"Bronze",
    Silver:"Silver",
    Gold:"Gold",
    Ruby:"Ruby",
    buy_now:'Buy Now',
    welcome_msg:'Mine CNFTG Everyday!',
    mint_promotion:'New user gets 500 CNFTG token and introducer gets 200 CNFTG tokens!',
    send_magic_link:'Send Magic login link',
    claimed_nft:'You claimed 1 NFT to your wallet!',
    teams:'Teams',
    recent_txn:"Recent Transactions",
    join_now:"Join premium member now",
    rule_1:"Free member can create 3 NFTs per day, 25 total. The NFT price must be $3 or more. Silver or above member can create any number of NFTs per day and set price to any number include 0 for promotions. ",
    issue_amount:"Issue amount",
    buy:"BUY",
    total_remain:"Total remain:",
    today_remain:"Today remain",
    no_free:"Free NFT is used out, please upgrade to membership.",
    member_info:"Upon confirmation of membership purchase,  you will receive a CNFT farmer NFT token minted to your wallet. ",
    go:"GO"
  },

  zh_CN: {
    //global
    create: '+创建',

    logout: '退出',
    signin: '登录/退出',
    magic_link_prompt: "请检查邮件获得登录链接. 如果没有收到,注意检查是否被识别垃圾邮件.",
    hello: '你好,欢迎来到CNFT!',
    view: '查看',
    allnft: '发现',
    price: '价格',
    total: '总计',
    remains: '剩余数量',
    faq: '答疑',
    terms: '平台条款',
    privacy: '隐私政策',
    content_rules:'请发布合法内容,禁止色情,暴力,种族歧视,政治等内容. 请保证拥有所发布内容的知识产权,您有责任保证发布NFT内容的合法性. 以上规则对CNFT社区非常重要,任何违反规则情况,我们将可能永久删除内容,删除账号,并不会通知. 我们保留向各国相关执法机构汇报和配合调查的权利. 发布NFT即视为接受此规则.',
    // My Settings 
    my_wallet:"我的钱包",
    my_settings: '我的设置',
    upload_cover: '上传封面图片',
    upload_avatar: '上传头像',
    nickname: '昵称',
    choose_your: '选择你的',
    bio: '介绍',
    your: '你的',
    set_your: '设置你的',
    website: '网站',
    facebook: 'facebook 帐户',
    twitter: '推特账户',
    Instagram: 'Instagram 帐户',
    video_link: '视频链接',
    shop_link: '商店链接',
    blog_link: '博客链接',
    profile: '您的个人资料',
    wallet_address: 'Polygon 上的钱包地址',
    wallet_key:"钱包私钥",
    matic_balance: 'Matic 余额',
    user_type: '用户类型',
    referrer: '推荐人用户 ID',
    downgrade: '降级到客户',
    upgrade: '升级到卖家',
    // additional gray text 10-9-2021 // 
    choose_your_nickname: '选择您的昵称',
    your_bio: '您的个人介绍',
    set_your_website: '设置您的网站', 
    set_your_facebook: '设置您的 Facebook 帐户', 
    set_your_twitter: '设置您的推特账号',
    set_your_instagram: '设置您的Instagram账号', 
    set_your_video: '设置您的视频链接', 
    set_your_shop: '设置您的商店链接', 
    set_your_blog: '设置您的博客链接', 

    // 我的团队
    my_team: '我的团队',
    teammates: '队友',

    // 我的代币
    my_nfts: '我的NFT',
    token: '收藏',
    show_on_profile: '在个人页面上显示',
    view_origin: '查看收藏',
    transfer:'转账',

    // NFT 项目页面
    sold_by : '卖家',
    free: '免费',
    create_date: '创建日期',
    contract:'合约地址',
    author:'作者',
    about:'关于',
    free_claim: '免费领取',
    buy_direct: '支付说明',
    sold_out: '售罄', 
    no_payment_option: '无付款选项',
    more_from_seller: '更多来自卖家',
    you_are_buying: '您将购买',
    cancel: '取消',
    make_payment: '信用卡付款',
    is_claimed: '已经领取',

    // 我的店铺
    my_shop: '我的商店',
    wallet: '钱包',
    create_nft: '创建新的 NFT',
    join_nft:'加入CNFT，免费获得NFT!',
    mint: '铸造',
    edit: '编辑',
    listing_for_sale: '待售清单',

    // 创建或编辑页面
    edit_nft:'编辑 NFT',
    upload_nft: '上传 NFT 图片',
    nft_title: 'NFT 标题 *',
    nft_author: 'NFT 作者',
    nft_desc: '你的 NFT 描述 *',
    external_link: '外部链接',
    url_https: '*URL 以 https:// 开头',
    audio: '音频网址',
    video: '视频网址',
    nft_price: 'NFT 价格 *',
    nft_count: 'NFT 计数 *',
    token_quantity: 'NFT 代币数量',
    payment_options: '付款人选项',
    credit_card: '信用卡',
    mint_now: '现在铸造',

    // additional gray text 10-9-2021 // 
    choose_your_nft_name: '选择您的 NFT 标题', 
    set_your_nft_sell_price: '设置您的 NFT 销售价格',
    set_your_nft_token_amount: '设置您的 NFT 销售数量', 


    // 我的个人资料

    my_profile: '我的主页',

    //我的订单
    my_orders: '我的订单',
    order_id: '订单编号',
    status: '状态',
    from: '来自:',
    pending: '待定',
    done: '完成',
    failed: '失败',
    pay: '支付',

    // 我的销售:
    my_sales: '我的销售',
    purchase_date: '购买日期',
    purchase_price: '购买价格',

    //links
    link_faq: '/faq',
    buycnftg: '购买CNFTG',
    cnftg_rewards:'CNFTG奖励',
    cnftg_balance:'Polygon钱包CNFTG余额',

    high_gas_fee: '由于当前Polygon链Gas费异常，您的请求暂时被限制。请升级用户等级获得更高权限或者等待Polygon链Gas费恢复正常。',
    membership:"会员",
    CLAIMED:"已经领取,每个用户只能领取一个,谢谢参与!",
    share:"分享 + 赚币",
    Bronze:"青铜会员",
    Silver:"白银会员",
    Gold:"黄金会员",
    Ruby:"宝石会员",
    buy_now:'立即购买',
    welcome_msg:'每天赚取CNFTG!',
    mint_promotion:'新会员获得500平台CNFTG币，介绍人获得200平台CNFTG币!',
    send_magic_link:'发送登录链接',
    claimed_nft:'您领取了 1 个 NFT!',
    teams:"团队",
    recent_txn:"最近交易记录",
    join_now:"立即加入高级会员",
    rule_1:"免费会员每天可以创建 3 个 NFT合约，最多25个，NFT 价格必须在 3 美元或以上。青铜及以上会员每天可以创建任意数量的 NFT合约，并可以设置任意价格用于促销。",
    issue_amount:"发行数量",
    buy:"购买",
    total_remain:"总剩余",
    today_remain:"今日剩余",
    no_free:"免費額度已用完，請購買會員。",
    member_info:"确认购买会员后，您将收到一个铸造到您钱包中的 CNFT 农民 NFT 。",
    go:"前往"
  }
}

export default msg
