<template>
  <div class="q-page">
    <div class="row" style="overflow: hidden; position: relative">
      <img v-if="pickone" class="my-bg" :src="pickone.data.image" />
      <div class="col my-bg-top">
        <div class="row my-nested q-mt-xl">
          <div
            class="col-md-8 col-sm-12 col-xs-12 col-lg-8 q-pa-md q-pl-lg q-pr-lg"
          >
            <div>
              <p style="font-weight: bold; font-size: 3vh; line-height: 68px">
                Discover,Create,Trade NFTs
              </p>
              <p style="font-weight: bold; font-size: 5vh; line-height: 68px">
                Get Rewards
              </p>
              <p class="text-body2">
                Your attention is value! stop squandering precious time and
                start earning CNFTG now !
              </p>
            </div>
            <div>
              <q-btn
                class="q-mr-md q-pl-lg q-pr-lg text-bold"
                color="secondary"
                rounded
                size="lg"
                :label="$t('allnft')"
                no-caps
                @click="this.$router.push('/discover')"
              />
              <q-btn
                class="q-pl-lg q-pr-lg text-bold"
                text-color="secondary"
                color="accent"
                no-caps
                rounded
                size="lg"
                :label="$t('create')"
                @click="this.$router.push('/mint')"
              />
            </div>
            <div class="q-mt-lg">
              <!-- <q-btn
                class="text-bold"
                text-color="white"
                color="accent"
                flat
                size="sm"
                label="Recent Sale"
                @click="this.$router.push('/discover')"
              />
              <q-btn
                class="text-bold"
                text-color="white"
                color="accent"
                flat
                size="sm"
                label="Recent Sale"
                @click="this.$router.push('/discover')"
              />
              <q-btn
                class="text-bold"
                text-color="white"
                color="accent"
                flat
                size="sm"
                label="Recent Sale"
                @click="this.$router.push('/discover')"
              /> -->
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 q-pa-md">
            <q-card v-if="pickone" class="my-one-card">
              <a :href="'/nft/' + pickone.id">
                <q-img
                  class="my-one-img"
                  ratio="1"
                  :src="pickone.data.image"
                  :alt="pickone.data.description"
                >
                </q-img>
              </a>
              <q-img
                v-if="pickone.data.price == 0"
                width="3rem"
                class="absolute-top-right"
                src="@/assets/free.png"
              />
              <q-card-section>
                <span class="float-right">
                  Creater: {{ pickone.data.author }}
                </span>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>

    <div class="q-pa-md row my-nested">
      <div class="col">
        <div class="row text-h5 q-mt-xl q-mb-md">
          A new way to mint NFT collections
        </div>
        <q-card class="row my-card q-pa-lg">
          <div class="col my-mining">
              DiscoverQuest
          </div>
          <div class="col-4 my-mining">
            <q-btn
              class="text-bold q-pl-lg q-pr-lg float-right"
              rounded
              color="secondary"
              size="md"
              :label="$t('go')"
              @click="go('https://ezverse.net')"
            ></q-btn>
          </div>
        </q-card>
        <q-card class="row my-card q-pa-lg">
          <div class="col my-mining">
            Crypto Zodiacs
          </div>
          
          <div class="col-4 my-mining">
            <q-btn
              class="text-bold q-pl-lg q-pr-lg float-right"
              rounded
              color="secondary"
              size="md"
              :label="$t('go')"
              @click="go('https://cnft.one')"
            ></q-btn>
          </div>
        </q-card>
      </div>
    </div>

    <div class="q-pa-md row my-nested">
      <div class="col">
        <div class="row q-mb-md text-h5">Featured Drop</div>
        <div v-if="picknfts" class="row">
          <div
            v-for="k in picknfts"
            :key="k.id"
            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
          >
            <q-card class="q-ma-xs my-card">
              <a :href="'/nft/' + k.id">
                <q-img
                  class="my-card-img q-pa-md"
                  ratio="1"
                  :src="k.data.image"
                  :alt="k.data.description"
                >
                  <q-img
                    v-if="k.total - k.token_sum <= 0"
                    class="absolute-center"
                    src="@/assets/soldout.png"
                  />
                </q-img>
              </a>
              <q-card-section>
                <div class="my-nft-name">{{ k.data.name }}</div>
                <!-- <div class="text-body2">
                  {{ pdate(k.created_at) }}
                </div> -->
                <div class="row q-mt-md items-center">
                  <div class="col-3">
                    <q-avatar rounded style="border-radius: 18px">
                      <img :src="k.uowner.avatar" />
                    </q-avatar>
                  </div>
                  <div class="col">
                    <span color="grey ">Creator </span> <br />
                    <span class="truncate">{{ k.uowner.nickname }}</span>
                  </div>
                  <div class="col">
                    <span
                      class="bg-secondary q-pl-xs q-pr-xs q-pt-none q-pb-none float-right"
                      style="border-radius: 5px"
                    >
                      <q-icon color="red" size="16px" name="favorite" />
                      <span class="text-weight-bold" style="font-size: 12px">{{
                        k.likes
                      }}</span>
                    </span>
                  </div>
                </div>
                <hr style="border: 1px solid #14141f" />
                <div class="row">
                  <div class="col text-left">
                    <span class="text-grey">{{ $t("price") }}</span>
                    <br />
                    <span class="text-bold">${{ k.data.price }} </span>
                  </div>
                  <div class="col text-center">
                    <span class="text-grey">{{ $t("total") }}</span>
                    <br />
                    <span class="text-bold">{{ k.total }}</span>
                  </div>
                  <div class="col text-right">
                    <span class="text-grey">{{ $t("remains") }}</span>
                    <br />
                    <span class="text-bold">{{
                      k.total - k.token_sum > 0 ? k.total - k.token_sum : 0
                    }}</span>
                  </div>
                </div>
              </q-card-section>

              <q-img
                v-if="k.data.price == 0"
                width="4vw"
                class="absolute-top-right"
                src="@/assets/free.png"
              />
            </q-card>
          </div>
        </div>
      </div>
    </div>

    <div class="q-ma-md q-pa-md flex justify-center">
      <q-btn
        color="primary q-pa-md text-bold q-pl-xl q-pr-xl"
        style="border: 1px white solid"
        rounded
        size="md"
        @click="this.$router.push('/discover')"
      >
        Load More
      </q-btn>
    </div>

    <div class="q-mt-md q-pa-md row my-nested">
      <div class="col">
        <div class="row text-h5 q-mb-md">Popular Shop</div>
        <div v-if="pickshops" class="row my-list">
          <div
            v-for="k in pickshops"
            :key="k.id"
            class="q-pb-md col-xs-12 col-sm-6 col-md-4 col-lg-4"
          >
            <q-card class="q-ma-xs my-card">
              <a :href="'/shop/' + k.id">
                <q-img
                  class="my-card-img q-pa-md"
                  ratio="1.618"
                  :src="k.cover"
                />
              </a>
              <q-card-section>
                <div class="row items-center">
                  <div class="col-2">
                    <q-avatar>
                      <img :src="k.avatar" />
                    </q-avatar>
                  </div>
                  <div class="col q-ml-md truncate">
                    <span>{{ k.nickname }} </span><br />
                    <span class="text-grey">{{ k.bio }}</span>
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>

    <div class="q-pa-md row my-nested">
      <div class="col">
        <div class="row text-h5 q-mb-md">Featured Collectors</div>
        <div v-if="pickusers" class="row">
          <div
            v-for="k in pickusers"
            :key="k.id"
            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
          >
            <a :href="'/profile/' + k.id">
              <q-card
                class="my-card q-ma-sm"
                style="background: none; box-shadow: none"
              >
                <div class="row items-center">
                  <div class="col-4">
                    <q-avatar
                      size="96px"
                      rounded
                      ratio="1"
                      style="border-radius: 30px"
                    >
                      <img :src="k.avatar" />
                    </q-avatar>
                  </div>
                  <div class="col truncate q-ml-md">
                    <span>{{ k.nickname }}</span
                    ><br />
                    <span>{{ k.bio }}</span>
                  </div>
                </div>
              </q-card>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="q-pa-md q-mt-xl row my-nested">
      <div class="col">
        <div class="row text-h5">Membership</div>
        <div class="row text-primary">
          <Membership />
        </div>
      </div>
    </div>

    <div class="q-pa-md q-mt-lg row my-nested">
      <div class="col">
        <div class="row q-mb-lg text-h5">New Drops</div>
        <RecentNFT :count="8" />
      </div>
    </div>

    <div class="q-pa-xl row">
      <div class="col">
        <div class="row my-nested">
          <div class="col">
            <div class="row text-h5">👀 Did you know?</div>
            <div class="row my-nested my-you-know">
              In order to reward early adoption and incentivize membership
              growth, CNFT platform offers 4 tiers of membership designed to
              offer different benefits and features for each.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="sass" scoped>
.my-bg
  background-size: cover
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: auto
  opacity: 0.6
  filter: blur(30px)

.my-bg-top
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)
  opacity: 0.9

.my-one-card
  border-radius: 20px
  margin-bottom: 2rem
  padding: 20px

.my-one-img
  border-radius: 20px

.my-mining
  border-radius: 20px
  background-opacity: 0.6
  padding: 0.5rem
  font-size: 1.2rem

.my-cnftg
  font-family: Manrope
  font-style: normal
  font-weight: bold
  font-size: 1.2rem
  vertical-align: middle

.my-card
  border-radius: 15px
  padding: 10px
  margin-bottom: 10px
  background-opacity: 0.6
  .my-nft-name
    font-size: 18px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  .my-card-img
    border-radius: 15px

.my-you-know
  font-size: 2.5vh

// .my-content
//   cursor: pointer
//   text-align: center
//   padding: 12px
//   background-color: rgba(0, 0, 0, 0.5)

// .my-link
//   display: inline-block
//   width: 100%
//   overflow: hidden
//   text-overflow: ellipsis

// .my-list
//   margin-left: 0rem
//   margin-right: 0rem

// .my-avatar
//   position: absolute
//   left: 50%
//   margin-left: -100px
//   bottom: -100px

// .my-name
//   margin-top: 120px

// .my-card
//   cursor: pointer
//   .my-card-btn
//     width: 100%
//   .my-img
//     // height: 300px
</style>

<script>
import router from "@/router";
import { supabase as s } from "../supabase";
import { ref, onBeforeMount, inject } from "vue";
import RecentNFT from "./RecentNFT.vue";
import Membership from "./Member.vue";

import { useRoute } from "vue-router";
import { likeit } from "../web3";
import { openURL } from "quasar";

export default {
  components: {
    RecentNFT,
    Membership,
  },
  name: "AllNFTs",
  setup() {
    const pickone = ref(null);
    const picknfts = ref(null);
    const pickshops = ref(null);
    const pickusers = ref(null);
    const user = ref(s.auth.user());
    const profile = inject("profile");
    onBeforeMount(async () => {
      // set refer to local storage, send to server when user signin
      // if local has already has a referer or currently exist login user don't need set referer
      // only set referer for the clean new user first visit
      if (!localStorage.getItem("referer") && !user.value) {
        // query refer user info
        if (useRoute().query.rid == undefined) {
          console.log("No referer id, not a referer page");
        } else {
          const rid = useRoute().query.rid;
          console.log("referer uid", rid);
          let { data: u, error } = await s
            .from("users")
            .select("*")
            .eq("id", rid);
          if (error) console.log(error);
          else {
            console.log("Query refer user:", u[0]);
            localStorage.setItem("referer", u[0].id);
            console.log("set local referer id:", u[0].id);
          }
        }
      }

      {
        let { data: nfts, error } = await s
          .from("v_nfts")
          .select("*,uowner:uowner(*)")
          .gt("flag", 0)
          .order("flag", { ascending: true })
          .limit(8);
        if (error) console.log(error);
        console.log("picknfts:", nfts);
        picknfts.value = nfts;
      }
      {
        let { data: nfts, error } = await s
          .from("v_nfts")
          .select("*,uowner:uowner(*)")
          .eq("flag", 8888)
          .single();
        if (error) console.log(error);
        console.log("pickone:", nfts);
        if (!nfts) {
          pickone.value = picknfts.value[0];
        } else {
          pickone.value = nfts;
        }
        console.log("pickone:", nfts);
      }
      {
        let { data: shops, error } = await s
          .from("profiles")
          .select("*")
          .gt("flag", 0)
          .lt("flag", 1000)
          .order("flag", { ascending: true });
        if (error) console.log(error);
        console.log("pickshops:", shops);
        pickshops.value = shops;
      }
      {
        let { data: users, error } = await s
          .from("profiles")
          .select("*")
          .gt("flag", 999)
          .lt("flag", 2000)
          .order("flag", { ascending: true });
        if (error) console.log(error);
        console.log("pickusers:", users);
        pickusers.value = users;
      }
    });

    return {
      pickone,
      picknfts,
      pickshops,
      pickusers,
      likeit,
      profile,
      user,
    };
  },

  methods: {
    buy_token() {
      // openURL(
      //   "https://quickswap.exchange/#/swap?theme=light&inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x52a3fcb9d0a62eb507f3f9b4fa30d35ce03e6df8&exactAmount=0.01&exactField=input"
      // );
      openURL("https://www.CNFT.co");
    },
    go(url) {
      openURL(url);
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
  },
};
</script>
