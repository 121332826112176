import { render } from "./App.vue?vue&type=template&id=0a86bd3c"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"

import "./App.vue?vue&type=style&index=0&id=0a86bd3c&lang=sass"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QKnob from 'quasar/src/components/knob/QKnob.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QInput,QIcon,QSpace,QCircularProgress,QBtnDropdown,QAvatar,QList,QItem,QItemSection,QItemLabel,QImg,QDrawer,QToggle,QKnob,QScrollArea,QPageContainer,QPageSticky});qInstall(script, 'directives', {ClosePopup,Ripple});
