<template>
  <q-layout view="hHh LpR fFf">
    <q-header elevated>
      <q-toolbar class="q-ma-xs">
        <q-btn stretch flat to="/">
          <q-toolbar-title>
            <img class="my-logo" src="@/assets/logo.png" />
            <span class="q-ml-md my-logo">CNFT</span>
          </q-toolbar-title>
        </q-btn>

        <q-input
          class="hidden"
          dense
          rounded
          standout
          v-model="kword"
          input-class="text-left"
          size="25"
          style="font-size: 24px"
        >
          <template v-slot:append>
            <q-icon v-if="kword === ''" name="search" />
            <q-icon
              v-else
              name="clear"
              class="cursor-pointer"
              @click="kword = ''"
            />
          </template>
        </q-input>
        <q-space />

        <!-- <q-circular-progress
          v-if="mysession"
          show-value
          class="text-white"
          :value="timer_value"
          size="28px"
          :thickness="0.3"
          color="orange"
          instant-feedback
          @click="this.$router.push('/mywallet')"
        >
          <q-icon size="16px" color="orange" name="savings" />
        </q-circular-progress>
        <div
          v-if="mysession"
          class="my-cnftg"
          @click="this.$router.push('/mywallet')"
        >
          <span class="mobile-hide">Mining:</span>
          {{ balance }}
          <span>CNFTG</span>
        </div>

        <div v-else class="my-cnftg" @click="this.$router.push('/login')">
          {{ $t("welcome_msg") }}
        </div> -->
        <br />
        <q-space />
        <div class="gt-sm">
          <!-- <q-btn stretch flat :label="$t('buycnftg')" @click="buy_token()" /> -->
          <q-btn stretch no-caps flat :label="$t('allnft')" to="/discover" />
          <q-btn stretch no-caps flat :label="$t('membership')" to="/member" />
          <q-btn stretch no-caps flat :label="$t('create')" to="/mint" />
          <q-btn stretch no-caps flat :label="$t('faq')" to="/faq" />

          <q-btn
            v-if="!mysession"
            flat
            round
            icon="account_circle"
            v-on:click="login"
          />
          <q-btn-dropdown v-else flat id="my-btn-menu">
            <template v-slot:label>
              <div class="row items-center no-wrap">
                <q-avatar size="24px">
                  <img :src="myprofile.avatar" />
                </q-avatar>
              </div>
            </template>

            <q-list class="mylist">
              <!-- <q-item class="myitem" clickable v-close-popup to="/mywallet">
                <q-item-section avatar>
                  <q-icon name="paid" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("my_wallet") }}</q-item-label>
                </q-item-section>
              </q-item> -->
              <q-item
                active-class="text-orange"
                class="myitem"
                clickable
                v-close-popup
                to="/mysettings"
              >
                <q-item-section avatar>
                  <q-icon name="settings" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("my_settings") }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                active-class="text-orange"
                class="myitem"
                clickable
                v-close-popup
                :to="'/team/' + mysession.user.id"
              >
                <q-item-section avatar>
                  <q-icon name="group" />
                </q-item-section>
                <q-item-section>
                  {{ $t("my_team") }}
                </q-item-section>
              </q-item>

              <q-item
                active-class="text-orange"
                class="myitem"
                clickable
                v-close-popup
                to="/mynfts"
              >
                <q-item-section avatar>
                  <q-icon name="token" />
                </q-item-section>
                <q-item-section>
                  {{ $t("my_nfts") }}
                </q-item-section>
              </q-item>

              <q-item
                active-class="text-orange"
                class="myitem"
                v-if="myprofile.utype == 'seller'"
                clickable
                v-close-popup
                :to="'/shop/' + mysession.user.id"
              >
                <q-item-section avatar>
                  <q-icon name="store" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("my_shop") }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                active-class="text-orange"
                class="myitem"
                clickable
                v-close-popup
                :to="'/profile/' + mysession.user.id"
              >
                <q-item-section avatar>
                  <q-icon name="person" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("my_profile") }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                active-class="text-orange"
                class="myitem"
                clickable
                v-close-popup
                to="/myorders"
              >
                <q-item-section avatar>
                  <q-icon name="shopping_bag" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("my_orders") }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                active-class="text-orange"
                class="myitem"
                v-if="myprofile.utype == 'seller'"
                clickable
                v-close-popup
                to="/mysales"
              >
                <q-item-section avatar>
                  <q-icon name="money" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("my_sales") }}</q-item-label>
                </q-item-section>
              </q-item>

              <q-item
                active-class="text-orange"
                class="myitem"
                clickable
                v-close-popup
                @click="logout"
              >
                <q-item-section avatar>
                  <q-icon name="logout" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ $t("logout") }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>

        <q-btn
          class="lt-md"
          dense
          flat
          round
          icon="menu"
          @click="toggle_menu"
        />
        <q-btn-dropdown class="q-ma-none q-pa-sm" id="my-btn-lang" flat rounded>
          <template v-slot:label>
            <q-avatar size="26px">
              <img src="/img/icons/language.svg" />
            </q-avatar>
          </template>

          <q-list>
            <q-item
              class="myitem"
              clickable
              v-close-popup
              @click="set_lang('en')"
            >
              <!-- <q-item-section avatar>
                <q-icon>
                  <q-img src="/img/icons/english.png" />
                </q-icon>
              </q-item-section> -->
              <q-item-section>
                <q-item-label>English</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              class="myitem"
              clickable
              v-close-popup
              @click="set_lang('zh_CN')"
            >
              <!-- <q-item-section avatar>
                <q-icon>
                  <q-img src="/img/icons/chinese.png" />
                </q-icon>
              </q-item-section> -->
              <q-item-section>
                <q-item-label>中文</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-btn
          flat
          round
          icon="account_balance_wallet"
          v-on:click="show_wallet()"
        />
      </q-toolbar>
    </q-header>

    <!-- wallet drawer -->
    <q-drawer v-if="mysession" v-model="right_drawer" side="right" bordered>
      <div class="absolute" style="top: 80px; left: -17px">
        <q-btn
          v-if="right_drawer"
          dense
          round
          unelevated
          color="secondary"
          icon="chevron_right"
          @click="right_drawer = false"
        />
      </div>
      <!-- drawer content -->
      <div class="q-pa-md q-mt-lg row items-center">
        <div class="col">
          <q-avatar size="44px" class="float-left q-mr-md">
            <img :src="myprofile.avatar" />
          </q-avatar>
          <span>
            {{ myprofile.nickname }}

            <br />
            {{ $t(level_str) }}
          </span>
        </div>
        <div class="col">
          <q-toggle
            class="float-right"
            v-model="is_dark"
            color="secondary"
            icon="dark_mode"
          />
        </div>
      </div>

      <div class="q-pa-lg">
        <span>My Wallet</span><br />
        <q-input
          readonly
          v-model="user.user_metadata.wallet"
          :label="$t('wallet_address')"
        >
          <template v-slot:append>
            <q-icon
              name="content_copy"
              @click="copy(user.user_metadata.wallet)"
              class="cursor-pointer"
            />
          </template>
        </q-input>
      </div>
      <div class="q-ma-md q-pa-md my-balance">
        <!-- <q-avatar size="44px" class="float-left q-mr-md">
          <img src="@/assets/logo.png" />
        </q-avatar> -->
        <q-knob
          class="float-left q-mr-md"
          v-model="timer_value"
          :thickness="0.25"
          show-value
          color="secondary"
          track-color="orange"
          size="44px"
          instant-feedback
          @click="right_drawer = !right_drawer"
        >
          <q-avatar size="24px">
            <img src="@/assets/logo.png" />
          </q-avatar>
        </q-knob>
        <span class="text-grey">CNFTG REWARDS</span><br />
        <span class="text-bold text-body2">{{ balance }}</span>
      </div>

      <div class="q-ma-md q-pa-md my-balance">
        <q-avatar size="44px" class="float-left q-mr-md">
          <img src="@/assets/logo.png" />
        </q-avatar>
        <span class="text-grey">CNFTG WALLET</span><br />
        <span class="text-bold text-body2">{{ financial(cbalance) }}</span>
      </div>
      <div class="q-ma-md q-pa-md my-balance">
        <q-avatar size="44px" class="float-left q-mr-md">
          <img src="@/assets/matic.png" />
        </q-avatar>
        <span class="text-grey">MATIC BALANCE</span><br />
        <span class="text-bold text-body2">{{ financial(matic_balance) }}</span>
      </div>
      <hr class="q-ma-lg" />
      <div class="q-pa-lg">
        <q-input
          readonly
          v-model="user.user_metadata.key"
          :label="$t('wallet_key')"
          @click="show_type = 'text'"
          :type="show_type"
        >
          <template v-slot:append>
            <q-icon
              name="content_copy"
              @click="copy(user.user_metadata.key)"
              class="cursor-pointer"
            />
          </template>
        </q-input>
      </div>
      <div class="q-ma-lg text-center">
        <q-btn
          color="secondary"
          rounded
          class="full-width my-buy-cnftg q-pt-md q-pb-md"
          label="BUY CNFTG"
          @click="buy_token()"
        ></q-btn>
      </div>
    </q-drawer>

    <!-- menu drawer -->

    <q-drawer v-model="show_menu" side="left" bordered>
      <div class="absolute" style="top: 80px; right: -17px">
        <q-btn
          v-if="show_menu"
          dense
          round
          unelevated
          color="secondary"
          icon="chevron_left"
          @click="show_menu = false"
        />
      </div>
      <q-scroll-area class="fit">
        <q-list padding class="menu-list">
          <q-item active-class="text-orange" clickable v-ripple to="/discover">
            <q-item-section avatar>
              <q-icon name="explore" />
            </q-item-section>

            <q-item-section>
              {{ $t("allnft") }}
            </q-item-section>
          </q-item>
          <q-item active-class="text-orange" clickable v-ripple to="/member">
            <q-item-section avatar>
              <q-icon name="card_membership" />
            </q-item-section>

            <q-item-section>
              {{ $t("membership") }}
            </q-item-section>
          </q-item>
          <q-item active-class="text-orange" clickable v-ripple to="/mint">
            <q-item-section avatar>
              <q-icon name="create" />
            </q-item-section>
            <q-item-section>
              {{ $t("create") }}
            </q-item-section>
          </q-item>
          <q-item active-class="text-orange" clickable v-ripple to="/faq">
            <q-item-section avatar>
              <q-icon name="lightbulb" />
            </q-item-section>

            <q-item-section>
              {{ $t("faq") }}
            </q-item-section>
          </q-item>

          <div v-if="!mysession">
            <q-item active-class="text-orange" v-close-popup>
              <q-item-section avatar>
                <q-icon name="list" />
              </q-item-section>
              <q-item-section>
                <q-btn stretch flat :label="$t('signin')" v-on:click="login" />
              </q-item-section>
            </q-item>
          </div>
          <div v-else>
            <!-- <q-item class="myitem" clickable v-close-popup to="/mywallet">
              <q-item-section avatar>
                <q-icon name="paid" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ $t("my_wallet") }}</q-item-label>
              </q-item-section>
            </q-item> -->
            <q-item
              active-class="text-orange"
              clickable
              v-close-popup
              to="/mysettings"
            >
              <q-item-section avatar>
                <q-icon name="settings" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ $t("my_settings") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              active-class="text-orange"
              clickable
              v-close-popup
              :to="'/team/' + mysession.user.id"
            >
              <q-item-section avatar>
                <q-icon name="group" />
              </q-item-section>
              <q-item-section>
                {{ $t("my_team") }}
              </q-item-section>
            </q-item>

            <q-item
              active-class="text-orange"
              clickable
              v-close-popup
              to="/mynfts"
            >
              <q-item-section avatar>
                <q-icon name="token" />
              </q-item-section>
              <q-item-section>
                {{ $t("my_nfts") }}
              </q-item-section>
            </q-item>

            <q-item
              active-class="text-orange"
              v-if="myprofile.utype == 'seller'"
              clickable
              v-close-popup
              :to="'/shop/' + mysession.user.id"
            >
              <q-item-section avatar>
                <q-icon name="store" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ $t("my_shop") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              active-class="text-orange"
              clickable
              v-close-popup
              :to="'/profile/' + mysession.user.id"
            >
              <q-item-section avatar>
                <q-icon name="person" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ $t("my_profile") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              active-class="text-orange"
              clickable
              v-close-popup
              to="/myorders"
            >
              <q-item-section avatar>
                <q-icon name="shopping_bag" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ $t("my_orders") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              active-class="text-orange"
              v-if="myprofile.utype == 'seller'"
              clickable
              v-close-popup
              to="/mysales"
            >
              <q-item-section avatar>
                <q-icon name="money" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ $t("my_sales") }}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item
              active-class="text-orange"
              clickable
              v-close-popup
              @click="logout"
            >
              <q-item-section avatar>
                <q-icon name="logout" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ $t("logout") }}</q-item-label>
              </q-item-section>
            </q-item>
          </div>
        </q-list>
      </q-scroll-area>
    </q-drawer>
    <q-page-container>
      <router-view :key="$route.fullPath" />
      <q-page-sticky position="bottom-right" :offset="[18, 18]">
        <q-knob
          v-model="timer_value"
          :thickness="0.25"
          show-value
          color="secondary"
          track-color="orange"
          size="56px"
          instant-feedback
          @click="show_wallet()"
        >
          <q-avatar size="36px">
            <img src="@/assets/logo.png" />
          </q-avatar>
        </q-knob>
      </q-page-sticky>
    </q-page-container>

    <div>
      <div class="row my-nested q-pb-xl q-pt-xl text-center">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <q-img width="64px" class="q-ml-md" src="@/assets/logo.png" />
          <p class="q-ma-md" style="max-width: 300px; margin: auto">
            Your attention is value! stop squandering precious time and start
            earning CNFTG now !
          </p>
        </div>
        <!-- <div
          class="
            col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3
            text-center
            q-mt-md
          "
        >
          <div class="text-h6">My Account</div>
          <div class="text-body2">My Wallet</div>
          <div class="text-body2">My Shop</div>
          <div class="text-body2">My Profile</div>
          <div class="text-body2">My NFTs</div>
        </div>

        <div
          class="
            col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3
            text-center
            q-mt-md
          "
        >
          <div class="text-h6">Resources</div>
          <div class="text-body2">Membership</div>
          <div class="text-body2">FAQ</div>
          <div class="text-body2">Activity</div>
          <div class="text-body2">Buy CNFTG</div>
        </div> -->
        <div
          class="
            col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
            text-center
            q-mt-md
          "
        >
          <div class="text-h6">Contact Us</div>

          <div class="">
            <a href="https://discord.gg/ZDpthkp6yp">
              <q-avatar class="q-ma-sm" size="36px">
                <img src="@/assets/discord.jpg" />
              </q-avatar>
            </a>
            <a href="https://t.me/cnftco">
              <q-avatar class="q-ma-sm" size="36px">
                <img src="@/assets/telegram.png" />
              </q-avatar>
            </a>
            <a href="https://twitter.com/CNFT_co">
              <q-avatar class="q-ma-sm" size="36px">
                <img src="@/assets/twitter.png" />
              </q-avatar>
            </a>
            <a href="https://www.instagram.com/cnft_co/">
              <q-avatar class="q-ma-sm" size="36px">
                <img src="@/assets/instagram.png" />
              </q-avatar>
            </a>
          </div>
        </div>
      </div>
    </div>
  </q-layout>
</template>
<style lang="sass">
*
  font-family: Urbanist
  font-style: normal
  text-decoration: none
  .nft
    cursor: pointer
  .flink
    font-size: 14px
    font-weight: 400
  a, a:hover, a:focus, a:active
    text-decoration: none
    color: grey

.my-logo
  font-weight: bold
  font-size: 32px
  line-height: 56px
  vertical-align: middle

.my-nested
  max-width: 1250px
  margin-left: auto
  margin-right: auto

.my-title
  margin: 1rem
  font-size: 6vh

.my-notify
  font-size: 3rem
  background-color: #000

#my-btn-lang
  i
    display: none

#my-btn-menu
  i
    display: none

.my-balance
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
  border-radius: 13px
  padding: 1rem

.my-buy-cnftg
  background: linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%)
  border-radius: 13px

.truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
</style>
<script>
import { provide, ref } from "vue";
import { supabase } from "./supabase";
import { openURL, useQuasar } from "quasar";
import { useRoute, useRouter } from "vue-router";
import { w3, create_wallet, get_member_level, levels, APIURL } from "./web3.js";
import axios from "axios";
import { copyToClipboard } from "quasar";

export default {
  name: "App",
  setup(props, context) {
    const show_menu = ref(false);
    const show_banner = ref(true);
    const balance = ref(0);
    const mysession = ref(null);
    const myprofile = ref({});
    const user = ref(null);
    const route = useRoute();
    const cnftg_balance = ref(0);
    const mine_interval = ref(300);
    const timer_value = ref(0);
    const router = useRouter();
    const lang = ref("en");
    const rid = ref("");
    const $q = useQuasar();
    const matic_balance = ref(0);
    const cbalance = ref(0);
    const is_dark = ref(true);
    const level_str = ref("");
    provide("profile", myprofile);
    provide("user", user);
    provide("q", $q);

    let local_is_dark = window.localStorage.getItem("is_dark");
    console.log("read local dark mode:", local_is_dark);
    if (local_is_dark == 1) {
      is_dark.value = true;
      $q.dark.set(true);
    } else if (local_is_dark == 0) {
      is_dark.value = false;
      $q.dark.set(false);
    } else {
      is_dark.value = true;
      $q.dark.set(true);
    }

    // set status
    // or false or "auto"
    // code like shit, stupid router can not return query params here!!!
    var queries = {};
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        queries[item.split("=")[0]] = item.split("=")[1];
      });
    if (queries["lang"] != "undefined") {
      lang.value = queries["lang"];
      rid.value = queries["rid"];
    }
    if (lang.value == "en" || lang.value == "zh_CN") {
      window.localStorage.setItem("lang", lang.value);
      console.log("Get lang from url:", lang.value);
    }

    const toggle_menu = () => {
      show_menu.value = !show_menu.value;
      console.log("dismiss", show_banner);
    };

    const mining_timer = async () => {
      if (!mysession.value) {
        return;
      }
      timer_value.value++;
      if (timer_value.value > 100) {
        timer_value.value = 0;
        // mine one cnftg
        const { data, error } = await supabase.rpc("mine", {
          uid: myprofile.value.id,
        });
        console.log("Call mine return:", data);
        balance.value += data;
      }
    };

    const dismiss = () => {
      show_banner.value = ref(true);
      console.log("dismiss", show_banner);
    };

    supabase.auth.onAuthStateChange(async (event, session) => {
      console.log("Trigger onAuthStateChange");
      if (event == "SIGNED_OUT") {
        console.log("SIGNED_OUT", session);
      }

      if (event == "SIGNED_IN") {
        console.log("SIGNED_IN", session);
        mysession.value = session;
        // store.commit("set_user", {
        //   user: session.user,
        // });
        user.value = session.user;

        // top priority
        // if user has no wallet, create it
        if (undefined == session.user.user_metadata.wallet) {
          console.log("New user, no wallet, generate eth wallet");
          axios
            .get("https://cnft.us:5000/i/w")
            .then(async (res) => {
              if (res.status == 200) {
                console.log("Generated Wallet:", res.data);
                const { user_info, error } = await supabase.auth.update({
                  data: {
                    wallet: res.data.address,
                    key: res.data.privateKey,
                  },
                });
                if (error) {
                  console.log("Set user wallet failed! supabase.auth.update");
                } else {
                  console.log("Updated user info:", user_info);
                  user.value = user_info;
                }
              } else {
                console.log(res.status, res.statusText);
                alert("Create wallet failed! Please refresh page try again.");
              }
            })
            .catch((err) => {
              console.log(err.response);
              alert("Create wallet failed! Please refresh page try again.");
            });
        } else {
          console.log("has wallet:", session.user.user_metadata.wallet);
          console.log("login user:",session.user)
        }

        // insert profile record if not exist
        let { data: profile, error } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", session.user.id);
        if (error) {
          console.log(error);
        } else {
          console.log("profile:", profile);
        }
        if (profile.length == 0) {
          let { data: newprofile, error } = await supabase
            .from("profiles")
            .insert({
              id: session.user.id,
            });
          if (error) console.log(error);
          myprofile.value = newprofile;
          console.log("Add new profile", newprofile);
        } else {
          console.log("Get profile:", profile);
          myprofile.value = profile[0];
          provide("profile", myprofile.value);
          level_str.value = levels[profile[0].ulevel];
        }

        balance.value = myprofile.value.balance;

        // cbalance.value = myprofile.value.cbalance;
        w3.eth.getBalance(user.value.user_metadata.wallet).then((b) => {
          matic_balance.value = w3.utils.fromWei(b);
        });

        // call api get cnftg balance
        const cnftg_token_addr = "0x52a3fcb9d0a62eb507f3f9b4fa30d35ce03e6df8";
        const params = {
          acct: user.value.user_metadata.wallet,
          contract: cnftg_token_addr,
        };

        const options = {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          data: params,
          url: APIURL + "/i/b",
        };
        console.log(options);
        axios(options)
          .then(async (res) => {
            console.log(res);
            cbalance.value = res.data;
          })
          .catch((err) => {
            console.log(err.response);
          });

        // Set referid when login
        console.log("get rid from url:");
        if (rid.value) {
          const oldrid = myprofile.value.rid;
          // record referer
          console.log("old rid:", oldrid);
          if (!oldrid) {
            console.log(
              "Try update user",
              session.user.id,
              " referer:",
              rid.value
            );

            const { data, error } = await supabase
              .from("profiles")
              .update({ rid: rid.value })
              .match({ id: session.user.id });
            if (error) console.log(error);
            console.log("Update referer id to profile:", data);
          } else {
            console.log(
              "Try update rid to:",
              rid.value,
              " but referer is existed:",
              oldrid,
              " Skipped"
            );
          }
        } else {
          console.log("No rid, need not set referer user.");
        }
      }
      provide("profile",myprofile);
      if (myprofile.value.ulevel > 1) {
        mine_interval.value = 150;
      }
      // console.log("set interval:", mine_interval.value);
      // setInterval(mining_timer, mine_interval.value);
    });
    console.log("app setup ended!");
    return {
      right_drawer: ref(false),
      cnftg_balance,
      timer_value,
      user,
      show_menu,
      show_banner,
      toggle_menu,
      dismiss,
      balance,
      mysession,
      myprofile,
      mine_interval,
      lang,
      kword: ref(""),
      cbalance,
      matic_balance,
      copyToClipboard,
      $q,
      show_type: ref("password"),
      levels,
      is_dark,
      level_str,
    };
  },
  watch: {
    is_dark: function (v, e) {
      if (v) {
        window.localStorage.setItem("is_dark", 1);
      } else {
        window.localStorage.setItem("is_dark", 0);
      }
      this.$q.dark.set(v);
    },
  },

  mounted: function () {
    if (this.lang == "en" || this.lang == "zh_CN") {
      window.localStorage.setItem("lang", this.lang);
      console.log("Get lang from url:", this.lang);
      this.set_lang(this.lang);
    }
  },
  beforeUpdate: function () {
    console.log("get params in activated:", this.$route.query);
  },

  methods: {
    set_lang(e) {
      console.log(e);
      this.$i18n.locale = e;
      window.localStorage.setItem("lang", e);
    },

    login() {
      console.log("call login");
      console.log("src:", window.location.pathname);
      this.$router.push({
        path: "/login",
        query: {
          src: window.location.pathname,
        },
      });
    },

    async logout() {
      this.user = null;
      await supabase.auth.signOut();
      window.location.reload();
      console.log("logout");
    },

    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
    buy_token() {
      openURL(
        "https://quickswap.exchange/#/swap?theme=light&inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x52a3fcb9d0a62eb507f3f9b4fa30d35ce03e6df8&exactAmount=0.01&exactField=input"
      );
    },
    show_wallet() {
      if (this.mysession) {
        this.right_drawer = !this.right_drawer;
      } else {
        this.$router.push({
          path: "/login",
          query: {
            src: window.location.pathname,
          },
        });
      }
    },
    copy(i) {
      copyToClipboard(i);
      this.$q.notify("Copied! " + i);
    },
    financial(x) {
      return Number.parseFloat(x).toFixed(4);
    },
  },
};
</script>
