import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
//import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { createI18n } from 'vue-i18n'

import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

import msg from './locale'

const store = window.localStorage;
let lang = store.getItem("lang") ? store.getItem("lang"):'en'

const i18n = createI18n({
  locale: lang,
  fallbackLocale:'en',
  globalInjection: true,
  legacy: false,
  messages: msg
})

const app = createApp(App)
app.component("infinite-loading", InfiniteLoading);
app.use(Quasar, quasarUserOptions)
//  .use(store)
  .use(router)
  .use(i18n)
  .mount("#app")
