import { render } from "./AllNFTs.vue?vue&type=template&id=4974bde7&scoped=true"
import script from "./AllNFTs.vue?vue&type=script&lang=js"
export * from "./AllNFTs.vue?vue&type=script&lang=js"

import "./AllNFTs.vue?vue&type=style&index=0&id=4974bde7&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-4974bde7"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBtn,QCard,QImg,QCardSection,QAvatar,QIcon});
